<template>
    <section>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Choisir une image
                        </h4>
                        <div class="col-md-12">
                            <!-- <gallery :images="images" :index="index" @close="index = null"></gallery> -->
                            <div class="image" v-for="(image, imageIndex) in images" :key="imageIndex" @click="addImage(image)" :style="{ backgroundImage: 'url(' + image.url + ')'}">
                            </div>
                            <!-- <img class="image" src="https://picsum.photos/200/300" alt="image de test"> -->
                        </div>
                    </div>
                    <div class="card-footer d-flex justify-content-end">
                        <button class="btn btn-link">Annuler</button>
                        <button class="btn  btn-primary">Associer l'image </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
export default {
    name: 'add-image',
    components: {
      },
    data () {
        return {
          ulid: '',
          entitytype: '',
          //   images: [
          //   require('../../assets/images/samples/1280x768/1.jpg'),
          //   require('../../assets/images/samples/1280x768/2.jpg'),
          //   require('../../assets/images/samples/1280x768/3.jpg'),
          //   require('../../assets/images/samples/1280x768/4.jpg'),
          //   require('../../assets/images/samples/1280x768/5.jpg'),
          //   require('../../assets/images/samples/1280x768/6.jpg'),
          //   require('../../assets/images/samples/1280x768/7.jpg'),
          //   require('../../assets/images/samples/1280x768/8.jpg'),
          // ],
          images: [],
          index: null,
        }
    },
    methods: {
      getImages() {
        axios.get('imgs')
        .then(resGetImages => {
          this.images = resGetImages.data.result
        })
        .catch(errGetImages => console.log(errGetImages))
      },
      addImage(image) {
        console.log('image clické', image.url);
        console.log("ulid de l'image clickée", image.ulid);
        console.log("entitytype concerné", this.entitytype);
        console.log("ulid de l'entytype", this.ulid);
        axios.put(`imgs/${image.ulid}/${this.entitytype}/${this.ulid}`,{
          //emplacement data
        },{
          headers: {
            'X-AUTH-TOKEN': localStorage.getItem('token')
          }
        })
        .then(resAddImage => {
          console.log({resAddImage})
        })
        .catch (errAddImage => {
          console.log({errAddImage})
        })
      },
      saveImage() {
        
      }
    },
    mounted() {
      // préparation des paramètres reçus
      this.ulid = this.$route.params.ulid
      this.entitytype = this.$route.params.entitytype
      // chargement des images lors de la création de la page
      this.getImages()
    }
}
</script>

<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 15px;
    width: 22%;
    height: 220px;
  }
  @media (max-width: 1024px) {
    .image {
      width: 30%;
      margin: 1.5%;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 767px) {
    .image {
      width: 100%;
      margin: 0;
      margin-bottom: 15px;
    }
  }
</style> 
